import React, {useEffect, useMemo} from "react";
import FiltersPanel from "./FiltersPanel";
import Table from "../../common/table/Table";
import {columns} from "./Columns";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {actions as bikePartsActions} from "../../../actions/bikePartsActions";
import {connect} from "react-redux";
import SelectFilter from "../../common/table/filters/SelectFilter";

const PartList = ({actions, list, partCategoriesAll}) => {

  const onHandlePageChange = async (nextPage) => {
    await actions.getPartListNextPage(nextPage);
  }

  const onHandleFiltersChange = filterBy => {
    actions.getPartList(1, list.paging.pageSize, list.sortedBy, filterBy)
  }

  const onHandlePageSizeChange = nextPageSize => {
    actions.getPartList(1, nextPageSize, list.sortedBy, list.filteredBy)
  }
  const onHandleSortChange = sortBy => {
    actions.getPartList(1, list.paging.pageSize, sortBy, list.filteredBy);
  }

  useEffect(() => {
    actions.getPartList(1, list.paging.pageSize, list.sortedBy, list.filteredBy)
  }, []);


  const categoryListsMemo = useMemo(() => {
    if(!partCategoriesAll) return [];
    let list = []
    partCategoriesAll.forEach(item => {
      list[item.id] = {
        value: item.id,
        id: item.id,
        name: item.name
      }
    });
    return list;
  },[partCategoriesAll])

  return (
    <div>
      <FiltersPanel filteredBy={list.filteredBy} onFilteredChange={onHandleFiltersChange} />
      <Table
        columns={[
          /*{
          Header: '',
          id: 'icon',
          // eslint-disable-next-line react/display-name
          Cell: row => <ListItemMenu itemId={row.original.id} />,
          width: 40,
          sortable: false,
          Filter: () => null,
        },*/ ...columns, {
          Header: "Kategoria",
          id: "categoryId",
          width: 200,
          accessor: (b) => {
            if(!partCategoriesAll) return '';
            const category = partCategoriesAll.find(x => x.id === b.categoryId)
            if(category) return category.name
            return "Nie znaleziono"
          },
          // eslint-disable-next-line react/prop-types,react/display-name
          Filter: ({ filter, onChange }) => (
            <SelectFilter
              onChange={onChange}
              filter={filter}
              items={categoryListsMemo}
              label="filtruj..."
            />
          ),
        },
          {
            Header: "Tylko dla trybu uproszczonego",
            id: "isOnlyForSimpleWarehouse",
            width: 200,
            sortable: false,
            Filter: () => null,
            accessor: (b) => b.isOnlyForSimpleWarehouse ? "Tak" : "Nie",
            // eslint-disable-next-line react/prop-types,react/display-name
          }]}
        data={list.records}
        filteredBy={list.filteredBy}
        sortedBy={list.sortedBy}
        paging={list.paging}
        onFilteredChange={onHandleFiltersChange}
        onPageChange={onHandlePageChange}
        onPageSizeChange={onHandlePageSizeChange}
        onSortedChange={onHandleSortChange}
        loading={list.loading}
        minRows={0}
        keyField="id"
        getTrProps={() => {
          return {
            style: {
              height: "40px",
            },
          };
        }}
      />
    </div>
  )

}

PartList.propTypes = {
  list: PropTypes.object.isRequired,
  categoryList: PropTypes.object.isRequired,
  partCategoriesAll: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(bikePartsActions, dispatch)
  };
}

function mapStateToProps(state) {
  const bikePartsState = state.bikeParts;
  return {
    categoryList: bikePartsState.partCategories.pagedData,
    partCategoriesAll: bikePartsState.partCategoriesAll,
    list: bikePartsState.parts.pagedData,

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartList);
