import React from "react";
import {useDispatch} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {submit} from "redux-form";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import CategoryForm from "./CategoryForm";
const AddNewCategoryDialog = ({open, onCancel, onSubmit, submitting}) => {
  const dispatch = useDispatch();
  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>Dodawanie nowej kategorii</DialogTitle>
      <DialogContent>
        <CategoryForm onSubmit={onSubmit}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" onClick={() => dispatch(submit('addNewCategoryForm'))} disabled={submitting}
                color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddNewCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default AddNewCategoryDialog;
