import React, {useEffect, useState} from "react";
import DocumentTitle from "react-document-title";
import {Button, Paper, Tab} from "@material-ui/core";
import PartList from "./partList/PartList";
import AddNewCategoryDialog from "./category/AddNewCategoryDialog";
import {actions as bikePartsActions} from "../../actions/bikePartsActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import PartCategoryList from "./partCategoryList/PartCategoryList";
import AddNewBikePartDialog from "./part/AddNewBikePartDialog";

const WarehousePage = ({actions, fetchingPartCategoriesAll, partCategoriesAll, categoryList, parts}) => {

  const [showAddNewCategoryDialog, setShowAddNewCategoryDialog] = useState(false);
  const [showAddNewBikePartDialog, setShowAddNewBikePartDialog] = useState(false);

  const [submitting, setSubmitting] = useState(false);


  const [tab, setTab] = useState('1');
  const handleCreatePartCategory = async (data) => {
    try {
      setSubmitting(true);
      const ok = await actions.createCategory(data.categoryName);
      if (ok) {
        await actions.getCategoryList(1, categoryList.paging.pageSize, categoryList.sortedBy, categoryList.filteredBy)
        setShowAddNewCategoryDialog(false);
      }
    }
    finally {
      setSubmitting(false);
    }
  }

  const handleCreatePart = async (data) => {
    try {
      setSubmitting(true)
      const ok = await actions.createPart(data.partName, data.categoryId, data.isOnlyForSimpleWarehouse);
      if (ok) {
        actions.getPartList(1, parts.paging.pageSize, parts.sortedBy, parts.filteredBy)
        setShowAddNewBikePartDialog(false);
      }
    }
    finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
      actions.getCategoryListAll();
  }, []);

  return (
    <DocumentTitle title={'Części'}>
      <React.Fragment>
        <Paper style={{ marginRight: 60, marginLeft: 60, marginTop: 80, padding: 16 }}>
          <div style={{
            display: 'flex',
            gap: 10,
            justifyContent: 'flex-end'
          }}>
            <Button variant="contained" color="primary" onClick={() => {setShowAddNewCategoryDialog(true)}} >Dodaj nową kategorię</Button>
            <Button variant="contained" color="primary" onClick={() => {setShowAddNewBikePartDialog(true)}} >Dodaj nową część</Button>
          </div>
          <TabContext value={tab}>
          <div style={{marginTop: 10}}>
            <TabList
              variant="fullWidth"
              onChange={(e, value) => setTab(value)}
            >
              <Tab label="Lista kategorii" value="1" />
              <Tab label="Lista części" value="2" />
            </TabList>
          </div>
          <div>
            <TabPanel value="1"><PartCategoryList /></TabPanel>
            <TabPanel value="2"><PartList /></TabPanel>
          </div>
          </TabContext>
        </Paper>
        <AddNewCategoryDialog
          open={showAddNewCategoryDialog}
          onSubmit={handleCreatePartCategory}
          onCancel={() => {setShowAddNewCategoryDialog(false)}}
          loading={false}
          submitting={submitting}
        />
        <AddNewBikePartDialog
          open={showAddNewBikePartDialog}
          onSubmit={handleCreatePart}
          onCancel={() => {setShowAddNewBikePartDialog(false)}}
          loading={false}
          submitting={submitting}
          categoryList={partCategoriesAll}
          fetchingPartCategoriesAll={fetchingPartCategoriesAll}
        />

      </React.Fragment>
    </DocumentTitle>
  )
}

WarehousePage.propTypes = {
  actions: PropTypes.object.isRequired,
  partCategoriesAll: PropTypes.array.isRequired,
  categoryList: PropTypes.object.isRequired,
  parts: PropTypes.object.isRequired,
  fetchingPartCategoriesAll: PropTypes.bool.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(bikePartsActions, dispatch)
  };
}

function mapStateToProps(state) {
  const bikePartsState = state.bikeParts;
  return {
    partCategoriesAll: bikePartsState.partCategoriesAll,
    categoryList: bikePartsState.partCategories.pagedData,
    parts: bikePartsState.parts.pagedData,
    fetchingPartCategoriesAll: bikePartsState.fetchingPartCategoriesAll,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehousePage);
