import api from '../api/bikePartsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';
import moment from 'moment';
export const types = {
  GET_BIKE_PARTS: 'GET_BIKE_PARTS',
  GET_BIKE_PARTS_SUCCESS: 'GET_BIKE_PARTS_SUCCESS',
  GET_BIKE_PARTS_FAILURE: 'GET_BIKE_PARTS_FAILURE',
  CREATE_BIKE_PART_SUBMIT: 'CREATE_BIKE_PART_SUBMIT',
  CREATE_BIKE_PART_SUCCESS: 'CREATE_BIKE_PART_SUCCESS',
  CREATE_BIKE_PART_FAILURE: 'CREATE_BIKE_PART_FAILURE',
  DELETE_BIKE_PART_SUBMIT: 'DELETE_BIKE_PART_SUBMIT',
  DELETE_BIKE_PART_SUCCESS: 'DELETE_BIKE_PART_SUCCESS',
  DELETE_BIKE_PART_FAILURE: 'DELETE_BIKE_PART_FAILURE',
  GET_SERVICE_PARTS_REPORT: 'GET_SERVICE_PARTS_REPORT',
  GET_SERVICE_PARTS_REPORT_SUCCESS: 'GET_SERVICE_PARTS_REPORT_SUCCESS',
  CREATE_BIKE_PART_CATEGORY_SUBMIT: 'CREATE_BIKE_PART_CATEGORY_SUBMIT',
  CREATE_BIKE_PART_CATEGORY_SUCCESS: 'CREATE_BIKE_PART_CATEGORY_SUCCESS',
  CREATE_BIKE_PART_CATEGORY_FAILURE: 'CREATE_BIKE_PART_CATEGORY_FAILURE',
  GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
  GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
  GET_CATEGORY_LIST_ALL: 'GET_CATEGORY_LIST_ALL',
  GET_CATEGORY_LIST_ALL_SUCCESS: 'GET_CATEGORY_LIST_ALL_SUCCESS',
  CREATE_NEW_BIKE_PART_CATEGORY_SUBMIT: 'CREATE_NEW_BIKE_PART_CATEGORY_SUBMIT',
  CREATE_NEW_BIKE_PART_CATEGORY_SUCCESS: 'CREATE_NEW_BIKE_PART_CATEGORY_SUCCESS',
  CREATE_NEW_BIKE_PART_CATEGORY_FAILURE: 'CREATE_NEW_BIKE_PART_CATEGORY_FAILURE',
  GET_BIKE_PART_LIST: 'GET_BIKE_PART_LIST',
  GET_BIKE_PART_LIST_SUCCESS: 'GET_BIKE_PART_LIST_SUCCESS',
};

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  getAll: () => (dispatch) => {
    dispatch({ type: types.GET_BIKE_PARTS });
    return api.getAll().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_PARTS_SUCCESS, result: res.data || [] });
      } else {
        dispatch({ type: types.GET_BIKE_PARTS_FAILURE, result: [] });
      }
    });
  },

  generateReport: (dateFrom, dateTo) => (dispatch) => {
    dispatch({ type: types.GET_SERVICE_PARTS_REPORT });
    return api
      .getReport(moment(dateFrom).toJSON(), moment(dateTo).toJSON())
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.GET_SERVICE_PARTS_REPORT_SUCCESS });
          const disposition = response.headers['content-disposition'];
          const fileName = disposition ? disposition.match(/filename="(.+)"/)[1] : 'eksport.xlsx';
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas generowania raportu'));
          logger.error(response.data);
          return false;
        }
      });
  },

  create: name => (dispatch) => {
    dispatch({ type: types.CREATE_BIKE_PART_SUBMIT, name });

    return api.create(name).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_BIKE_PART_SUCCESS, id: res.data, name });
        dispatch(notificationActions.showSuccess('Część została dodana do katalogu'));
        return true;
      } else {
        dispatch({ type: types.CREATE_BIKE_PART_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas dodawania części do katalogu'));
        return logger.error(res.data);
      }
    });
  },

  createCategory: (categoryName) => (dispatch) => {
    dispatch({ type: types.CREATE_BIKE_PART_CATEGORY_SUBMIT, categoryName });
    return api.createCategory(categoryName).then(res => {
      if (res.ok) {
        if (!res.data.failure) {
          dispatch({type: types.CREATE_BIKE_PART_CATEGORY_SUCCESS, id: res.data, categoryName});
          dispatch(notificationActions.showSuccess('Kategoria została utworzona'));
          return true;
        } else {
          dispatch({ type: types.CREATE_BIKE_PART_CATEGORY_FAILURE });
          dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia kategorii'));
          return logger.error(res.data);
        }
      } else {
        dispatch({ type: types.CREATE_BIKE_PART_CATEGORY_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia kategorii'));
        return logger.error(res.data);
      }
    });
  },

  createPart: (partName, categoryId, isOnlyForSimpleWarehouse) => (dispatch) => {
    dispatch({ type: types.CREATE_NEW_BIKE_PART_CATEGORY_SUBMIT });
    return api.createPart(partName, categoryId, isOnlyForSimpleWarehouse)
      .then(res => {
      if (res.ok) {
        if (!res.data.failure) {
          dispatch({type: types.CREATE_NEW_BIKE_PART_CATEGORY_SUCCESS, id: res.data, partName, categoryId});
          dispatch(notificationActions.showSuccess('Części została utworzona'));
          return true;
        } else {
          dispatch({ type: types.CREATE_NEW_BIKE_PART_CATEGORY_FAILURE });
          dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia części'));
          return logger.error(res.data);
        }
      } else {
        dispatch({ type: types.CREATE_NEW_BIKE_PART_CATEGORY_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia części'));
        return logger.error(res.data);
      }
    });
  },

  getWarehouseReport: () => (dispatch) => {
    return api.getWarehouseReport()
      .then(response => {
        if (response.ok && response.data.success !== false) {
          const disposition = response.headers['content-disposition'];
          const fileName = disposition ? disposition.match(/filename="(.+)"/)[1] : 'eksport_magazyn.xlsx';
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas generowania raportu magazynowego'));
          logger.error(response.data);
          return false;
        }
      });
  },


  getCategoryListAll: () => (dispatch) => {
    dispatch({ type: types.GET_CATEGORY_LIST_ALL });
    return api.getCategoryListAll().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_CATEGORY_LIST_ALL_SUCCESS, result: res.data || {} });
      }
    });
  },

  getCategoryList: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.GET_CATEGORY_LIST, pageSize ,sortBy, filterBy, pageNumber });
    return api.getCategoryList(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_CATEGORY_LIST_SUCCESS, result: res.data || {} });
      }
    });
  },

  getCategoryListNextPage: (pageNumber) => (dispatch, getState) => {
    const sortBy = getState().bikeParts.partCategories.pagedData.sortedBy;
    const filterBy = getState().bikeParts.partCategories.pagedData.filteredBy;
    const pageSize = getState().reports.partCategories.pagedData.paging.pageSize;
    dispatch({ type: types.GET_CATEGORY_LIST, pageSize ,sortBy, filterBy, pageNumber });
    return api.getCategoryList(pageNumber, pageSize, sortBy ? sortBy : [], filterBy ? filterBy : []).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_CATEGORY_LIST_SUCCESS, result: res.data || {} });
      }
    });
  },

  getPartList: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.GET_BIKE_PART_LIST, pageSize ,sortBy, filterBy, pageNumber });
    return api.getPartList(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_PART_LIST_SUCCESS, result: res.data || {} });
      }
    });
  },

  getPartListNextPage: (pageNumber) => (dispatch, getState) => {
    const sortBy = getState().bikeParts.parts.pagedData.sortedBy;
    const filterBy = getState().bikeParts.parts.pagedData.filteredBy;
    const pageSize = getState().reports.parts.pagedData.paging.pageSize;
    dispatch({ type: types.GET_BIKE_PART_LIST, pageSize ,sortBy, filterBy, pageNumber });
    return api.getPartList(pageNumber, pageSize, sortBy ? sortBy : [], filterBy ? filterBy : []).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_PART_LIST_SUCCESS, result: res.data || {} });
      }
    });
  },

  delete: id => (dispatch) => {
    dispatch({ type: types.DELETE_BIKE_PART_SUBMIT, id });
    return api.delete(id).then(res => {
      if (res.ok) {
        dispatch({ type: types.DELETE_BIKE_PART_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Część została usunięta z katalogu'));
      } else {
        dispatch({ type: types.DELETE_BIKE_PART_SUBMIT, id });
        dispatch(notificationActions.showError('Wystąpił problem podczas usuwania części z katalogu'));
        return logger.error(res.data);
      }
    });
  },
};
