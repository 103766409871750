import React, {useEffect} from "react";
import FiltersPanel from "./FiltersPanel";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {actions as bikePartsActions} from "../../../actions/bikePartsActions";
import {connect} from "react-redux";
import Table from "../../common/table/Table";
import {columns} from "./Columns";

const PartCategoryList = ({actions, list}) => {

  const onHandlePageChange = async (nextPage) => {
   await actions.getCategoryListNextPage(nextPage);
  }

  const onHandleFiltersChange = filterBy => {
    actions.getCategoryList(1, list.paging.pageSize, list.sortedBy, filterBy)
  }

  const onHandlePageSizeChange = nextPageSize => {
    actions.getCategoryList(1, nextPageSize, list.sortedBy, list.filteredBy)
  }
  const onHandleSortChange = sortBy => {
    actions.getCategoryList(1, list.paging.pageSize, sortBy, list.filteredBy);
  }

  useEffect(() => {
    actions.getCategoryList(1, list.paging.pageSize, list.sortedBy, list.filteredBy)
  }, []);

  return (
    <div>
      <FiltersPanel filteredBy={list.filteredBy} onFilteredChange={onHandleFiltersChange} />
      <Table
        columns={[{
          Header: '',
          id: 'icon',
          // eslint-disable-next-line react/display-name
          Cell: () => {} /*<ListItemMenu itemId={row.original.id} />*/,
          width: 40,
          sortable: false,
          Filter: () => null,
        }, ...columns]}
        data={list.records}
        filteredBy={list.filteredBy}
        sortedBy={list.sortedBy}
        paging={list.paging}
        onFilteredChange={onHandleFiltersChange}
        onPageChange={onHandlePageChange}
        onPageSizeChange={onHandlePageSizeChange}
        onSortedChange={onHandleSortChange}
        loading={list.loading}
        minRows={0}
        getTrProps={() => {
          return {
            style: {
              height: "40px",
            },
          };
        }}
        keyField="id"
      />
    </div>
  )

}

PartCategoryList.propTypes = {
  list: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(bikePartsActions, dispatch)
  };
}

function mapStateToProps(state) {
  const bikePartsState = state.bikeParts;
  return {
    list: bikePartsState.partCategories.pagedData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartCategoryList);
